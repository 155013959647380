// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/Iax2nWjqQ";

const enabledGestures = {KkLn5ibCp: {hover: true}};

const cycleOrder = ["KkLn5ibCp"];

const serializationHash = "framer-Krzs4"

const variantClassNames = {KkLn5ibCp: "framer-v-15jv8ie"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.12, 0.23, 0.5, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, EZRdC4vBi: title ?? props.EZRdC4vBi ?? "Sign up for free", RZestmdBG: link ?? props.RZestmdBG} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EZRdC4vBi, RZestmdBG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "KkLn5ibCp", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={RZestmdBG}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-15jv8ie", className, classNames)} framer-1knqjbe`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"KkLn5ibCp"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-4a05075d-2780-4d59-ab76-0957b36f1d47, rgb(0, 46, 37))", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}} variants={{"KkLn5ibCp-hover": {backgroundColor: "var(--token-e8574d05-7b36-41db-a990-3cfea99f91f4, rgb(245, 133, 113))"}}} {...addPropertyOverrides({"KkLn5ibCp-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-lebpet"} data-styles-preset={"Iax2nWjqQ"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-01582b51-a96b-42d6-8df4-b48063fc75fc, rgb(255, 255, 255)))"}}>Sign up for free</motion.p></React.Fragment>} className={"framer-50nmrg"} data-framer-name={"Sign up for free"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"iTxTpxaZ7"} style={{"--extracted-r6o4lv": "var(--token-01582b51-a96b-42d6-8df4-b48063fc75fc, rgb(255, 255, 255))"}} text={EZRdC4vBi} verticalAlignment={"center"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Krzs4.framer-1knqjbe, .framer-Krzs4 .framer-1knqjbe { display: block; }", ".framer-Krzs4.framer-15jv8ie { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 16px 8px 16px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-Krzs4 .framer-50nmrg { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Krzs4.framer-15jv8ie { gap: 0px; } .framer-Krzs4.framer-15jv8ie > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-Krzs4.framer-15jv8ie > :first-child { margin-left: 0px; } .framer-Krzs4.framer-15jv8ie > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 134
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ghhRX_wzQ":{"layout":["auto","auto"]}}}
 * @framerVariables {"EZRdC4vBi":"title","RZestmdBG":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTuNJdJkQa: React.ComponentType<Props> = withCSS(Component, css, "framer-Krzs4") as typeof Component;
export default FramerTuNJdJkQa;

FramerTuNJdJkQa.displayName = "Button/Nav Primary";

FramerTuNJdJkQa.defaultProps = {height: 40, width: 134};

addPropertyControls(FramerTuNJdJkQa, {EZRdC4vBi: {defaultValue: "Sign up for free", displayTextArea: false, title: "Title", type: ControlType.String}, RZestmdBG: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerTuNJdJkQa, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})